// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic', 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #0073ba;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #eb1c23;
$orange: #f38314;
$yellow: #f5c20a;
$green: #72bd44;
$teal: #4dc0b5;
$cyan: #6cb2eb;
